"use client"

import { MuiCostcoTheme } from "@costcolabs/forge-components"
import * as React from "react"
import NextAppDirEmotionCacheProvider from "./EmotionCache"
import { ThemeProvider } from "@mui/system"

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={{ ...MuiCostcoTheme }}>{children}</ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
}
