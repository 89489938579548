"use client"

import { useSearchParams } from "next/navigation"
import { ReactNode, Suspense } from "react"

type ToggleComponentBySearchParamProps = {
  children: ReactNode
  filterOptions: filterOptions
}

type filterOptions = {
  [key: string]: string | boolean | number
}

// Prevents this issue from being introduced: https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
const ToggleComponentBySearchParam = ({ children, filterOptions }: ToggleComponentBySearchParamProps) => {
  return (
    <Suspense fallback={children}>
      <ToggleComponentBySearchParamWithoutSuspense filterOptions={filterOptions}>
        {children}
      </ToggleComponentBySearchParamWithoutSuspense>
    </Suspense>
  )
}

const ToggleComponentBySearchParamWithoutSuspense = ({
  children,
  filterOptions,
}: ToggleComponentBySearchParamProps) => {
  const searchParams = useSearchParams()
  let showComponent = true

  for (let key in filterOptions) {
    if (searchParams.get(key) === filterOptions[key]?.toString()) {
      showComponent = false
    }
  }

  if (!showComponent) {
    return null
  }

  return children
}

export default ToggleComponentBySearchParam
