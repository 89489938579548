"use client"

import { useEffect, useRef } from "react"
import { useCookies } from "react-cookie"

/**
 * There was a non-prod script for One Trust that mistakenly reached production which when
 * combined with WCS cookies will create duplicate OptanonConsent cookies. For users that
 * loaded the non-prod script, the page refresh on cookie settings change will not work and
 * users won't see their change reflect on the page. This component makes sure the non-prod
 * cookie will be removed.
 *
 * The OneTrust cookies TTL is about 1 year and this can be safely removed by June 2025.
 */

const LEGACY_COOKIE_ONE_TRUST = "OptanonConsent"

export const CleanupOneTrustCookie = () => {
  // This is just used to detect whether or not cookies were changed
  const [cookies] = useCookies() // no dependencies as this will not work with duplicate cookies
  const cookieDeletedRef = useRef(false)

  useEffect(() => {
    if (
      window &&
      process.env.NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION === "prod" &&
      cookieDeletedRef.current === false
    ) {
      const optanonCookieCount = (document.cookie.match(/OptanonConsent/g) || []).length
      if (optanonCookieCount > 1) {
        // if duplicate cookies are detected, delete the one with domain set to www.costco.com
        // this attempts to overwrite the duplicate cookie, force it to expire so the browser
        // can get rid of it.
        document.cookie = `${LEGACY_COOKIE_ONE_TRUST}=;expires=Sat, 01-Jan-2000 00:00:00 GMT`
        cookieDeletedRef.current = true
      }
    }
  }, [cookies])

  return null
}
