"use client"

import { useEffect } from "react"
import { FEEDBACK_SCRIPT_SRC_US, FEEDBACK_SCRIPT_SRC_CA } from "./constants"

/**
 * Load the external OpinionLab script required by the FeedbackLink component.
 * This will also add the "feedback tab" on the side of the page.
 * This hook is only used for the purposes of the Storybook demo and is not used in the component itself.
 */
export function useOpinionLabScript(locale: string) {
  useEffect(() => {
    const head = document.querySelector("head")
    if (!head) {
      return
    }

    const script = document.createElement("script")

    switch (locale) {
      case "fr-ca":
      case "en-ca":
        script.setAttribute("src", FEEDBACK_SCRIPT_SRC_CA)
        break
      case "en-us":
      default:
        script.setAttribute("src", FEEDBACK_SCRIPT_SRC_US)
        break
    }

    script.setAttribute("data-document-language", "true")
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [locale])
}
