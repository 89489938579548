"use client"

import * as LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"

const CustomerTelemetry = () => {
  // only initialize when in the browser
  if (process.env.NEXT_PUBLIC_LOGROCKET_CUSTOMERRECORDING_APPID && typeof window !== "undefined") {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_CUSTOMERRECORDING_APPID, {
      console: {
        isEnabled: true,
      },
    })
    LogRocket.track("BModHomPage")
    LogRocket.log("BMod Homepage triggered")
    try {
      if (localStorage?.getItem("debugLogRocket") === "true") {
        window.LogRocket = LogRocket // If we need to enable logrocket to be accessed from console
      }
    } catch (e) {
      console.error(`localStorage not defined`)
    }
    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket)
  }
  return null
}

export default CustomerTelemetry
